/**
 * Component Navbar
 * Developed by David Marin
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Link
  } from "react-router-dom"

function Navbar({user}) {
    
    const [tc, setTC] = useState([]);
    
    useEffect(() => {
    axios.get('https://www.megatravel.com.mx/developers/tc/now').then(res => {
	const tc = res.data;
    setTC(tc);  
    
    });
    }, [])

    return (
        <>
        <header>
        
		<nav className="navbar navbar-expand-lg navbar-light main fixed-top back-azul-dark">
			<div className="container-fluid">
				<a className="navbar-brand" href="/">
 				<img src={user.logo} alt="" className="logo-img" />

				</a>
				<button className="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button"><i className="fas fa-bars" aria-hidden="true"></i></button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						
						<li className="nav-item">
							<a className="nav-link nav_link link-azul-menu" href="/#about">Nosotros</a>
						</li>
						<li className="nav-item">
							
							<Link className="nav-link nav_link link-azul-menu" to="/ofertas">Ofertas y Promociones</Link>
						</li>
						<li className="nav-item dropdown position-static">
							<a className="nav-link nav_link link-azul-menu dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdown" role="button">Destinos</a>
							<ul className="dropdown-menu megamenu back-azul-dark">
								<div className="row">
									<li className="col-md-3">
										<ul>
											
											<li>
												<Link to="/europa" className="nav_link_sub color-azul-dark submenu-azul-hover">viajes a europa</Link>
											</li>
											<li>
												<Link to="/canada" className="nav_link_sub color-azul-dark submenu-azul-hover">canadá</Link>
											</li>
											<li>
												<Link to="/estados-unidos" className="nav_link_sub color-azul-dark submenu-azul-hover">estados unidos</Link>
											</li>
											<li>
												<Link to="/mexico" className="nav_link_sub color-azul-dark submenu-azul-hover">méxico</Link>
											</li>
										</ul>
									</li>
									<li className="col-md-3">
										<ul>
											
											<li>
												<Link to="/sudamerica" className="nav_link_sub color-azul-dark submenu-azul-hover">sudamérica</Link>
											</li>
											<li>
												<Link to="/centroamerica" className="nav_link_sub color-azul-dark submenu-azul-hover">centroamérica</Link>
											</li>
											<li>
												<Link to="/pacifico" className="nav_link_sub color-azul-dark submenu-azul-hover">pacífico</Link>
											</li>
											<li>
												<Link to="/medio-oriente" className="nav_link_sub color-azul-dark submenu-azul-hover">medio oriente</Link>
											</li>
										</ul>
									</li>
									<li className="col-md-3">
										<ul>
										
											<li>
												<Link to="/asia" className="nav_link_sub color-azul-dark submenu-azul-hover">asia</Link>
											</li>
											<li>
												<Link to="/africa" className="nav_link_sub color-azul-dark submenu-azul-hover">africa</Link>
											</li>
											<li>
												<Link to="/eventos" className="nav_link_sub color-azul-dark submenu-azul-hover">eventos</Link>
											</li>
											<li>
												<Link to="/cruceros" className="nav_link_sub color-azul-dark submenu-azul-hover">cruceros</Link>
											</li>
										</ul>
									</li>
									<li className="col-md-3">
										<ul>
											
											<li>
												<Link to="/juvi" className="nav_link_sub color-azul-dark submenu-azul-hover">#juvi</Link>
											</li>
											<li>
												<Link to="/promociones" className="nav_link_sub color-azul-dark submenu-azul-hover">Mejores Ofertas</Link>
											</li>
											<li>
												<Link to="/exoticos" className="nav_link_sub color-azul-dark submenu-azul-hover">exoticos</Link>
											</li>
											
										</ul>
									</li>
								</div>
							</ul>
						</li>
						<li className="nav-item">
							<a className="nav-link nav_link link-azul-menu"  href="#contacto">Contacto</a>
						</li>
						<li className="nav-item">
							<a className="nav-link nav_link link-azul-menu"  href="/"><i className="far fa-money-bill-alt" aria-hidden="true"></i> MXN {tc}</a>
						</li>
						

						
					</ul>
				</div>
			</div>
		</nav>
    </header>
        </>
    )
}

export default Navbar
